@import 'foundation_settings';
@import 'media';
// div with id 'launcher' is created by
// zendesk when script loads and is the
// help button at bottom right of screen.
// Because we are using our own button, always
// hide this button
#launcher {
  display: none;
}

.zendesk-help-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: $orange;
  padding: .5rem 1.3rem;
  cursor: pointer;
  border-radius: 1.5rem;

  @include breakpoint(md-down) {
    display: none;
  }

  .zendesk-help-button-inner {
    position: relative;
  }

  &.loading {
    .zendesk-help-button-content {
      visibility: hidden;
    }
    .zendesk-loading-container {
      display: block;
    }
  }

  .zendesk-help-button-content {
    display: flex;
    align-items: center;
    .icon-container {
      margin-right: .7rem;
      display: flex;
      align-items: center;
      transform: translateY(-.1rem);
      .zendesk-comment-icon {
        color: white;
      }
    }
    .zendesk-label {
      color: white;
      font-size: 1rem;
    }
  }

  .zendesk-loading-container {
    display: none;
    overflow: visible;
    position: absolute;
    top: 40%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 100%;

    .loading-dots {
      // TODO: figure out why loading dots aren't
      // centered correctly using absolute positioning
      margin-left: -.5rem;
      svg {
        fill: #fff !important;
        height: 5rem !important;
        padding: 0 !important;
        margin: -2.1rem auto !important;
      }
    }
  }
}
