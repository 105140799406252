// font size
$base-font-size: 1em;

// font family
$primary-font: 'Larsseit', sans-serif;
$primary-font-medium: 'Larsseit', sans-serif;
$primary-font-bold: 'Larsseit', sans-serif;
$secondary-font-bold: 'Larsseit', sans-serif;

//primary colors
$primary-color: #ef5e12; //orange
$cta-color: #ef5e12; //orange
$cta-color-inactive: #f9bfa0; //light orange

//accent colors
$error-color: #be302b; //red
$accent-color-1: #45b49f; //teal
$accent-color-2: #ffbf0f; //yellow
$accent-color-3: #4774b5; //blue
$referral-color: #eae6e5; //temporary color for referral pages--based on current marketing creative.
$referral-blue: #015f82;
$dark-blue: #045d89;

//whites, blacks, grays
$white: #fff;
$black: #231f1a;
$gray-dark: #706c68;
$gray-medium: #b7b4b0;
$gray-light: #e4e0db;

$gray-100: #f5f5f5;
$gray-200: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #BDBDBF;
$gray-500: #9E9E9E;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$gray-background: #F3F3F3;
$gray-background-office: #F6F6F4;
$gray-border: #DADADA;

$shadow-black: rgba(0, 0, 0, 0.3);
$white-translucent: rgba(255, 255, 255, 0.9);

// aliases
$orange: $primary-color;
$orange-hover: darken($orange, 7%);
$teal: $accent-color-1;
$teal-dark: darken($teal, 10%);
$yellow-background: #F9C841;
$royal-blue: #0D2383;
$facebook-blue: #3b5998;
$red: #BE302C;
$error-red: darken(red, 10%);

//buttons
$button-background: $white;
$button-border: 1px solid $gray-medium;
$button-border-radius: 3px;
$button-font-family: $primary-font;
$button-font-weight: normal;
$button-height: 3rem;
$button-large-height: 3.5rem;

//content
$content-padding: 0.5rem;
$content-width: 1280px;
$snack-card-border: 1px solid $gray-light;
$snack-card-background: $white;

$text-1: $gray-900;
$text-2: $gray-dark;

$page-max-width: 1920px;

@mixin hover-pointer {
  cursor: pointer;
}

// NOTE: below animations are duplicated in new file
// animations.scss. All animations should go to new new file;
// not doing now because quite a few scss files will require
// having their import statements updated
@keyframes fade-in-key-frames {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@mixin fade-in {
  animation: fade-in-key-frames .4s linear forwards;
}

@keyframes scale-in-key-frames {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@mixin scale-in($time) {
  animation: scale-in-key-frames $time linear forwards;
}

@mixin transition($type, $time: 1s, $fade: ease-in-out) {
  transition: $type $time $fade;
}

.accent-1 {
  color: $accent-color-1;
}

// TODO: below cta mixins should be cleaned up
// / removed as soon as we have switched away
// from old cta button (this might only be used
// in AddProduct.tsx)
@mixin cta() {
  background-color: $cta-color;
  border: none;
  border-radius: rem-calc(3px);
  color: $white;
  font-family: $primary-font;
  font-size: rem-calc(16);
  height: $button-height;
  line-height: 1;
  min-width: rem-calc(100px);
  padding: calc((3rem - 1rem) / 2) 0;
  text-align: center;

  &:focus,
  &:hover {
    outline: none;
    background-color: $cta-color;
  }

  a {
    color: $white;
  }
}

@mixin cta-inactive() {
  background-color: $cta-color-inactive;
}

@mixin cta-large() {
  @include cta;
  height: $button-large-height;
  min-width: rem-calc(300px);
  padding: calc((3rem - 1.125em) / 2) 0;
}

@mixin cta-secondary($cta-text-color: $black, $cta-border-color: $gray-medium) {
  @include cta;
  background-color: $white;
  border: 1px solid $cta-border-color;
  color: $cta-text-color;

  &:focus,
  &:hover {
    background-color: $white;
    color: $cta-text-color;
    outline: none;
  }

  a {
    color: $cta-text-color;
  }
}

@mixin cta-secondary-color() {
  @include cta-secondary($cta-color, $cta-color);
}

@mixin orange-plus-button() {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-size: 15px;
  background-position: center;
}


// TODO: see if we can deprecate / remove
// below settings

$row-width: rem-calc(1200);
// $total-columns: 12;
$column-gutter: rem-calc(20);

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

// We use these to define default font stacks
$font-family-sans-serif: Roboto, Arial, sans-serif;
// $font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
// $font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// We use these to control various global styles
$body-bg: #fff !default;
$body-font-color: #777;
$body-font-family: $font-family-sans-serif;
$body-font-weight: $font-weight-normal !default;
$body-font-style: normal !default;

$base-font-size: 100% !default;

// $base-line-height is 24px while $base-font-size is 16px
$base-line-height: 1.5 !default;

$small-breakpoint: em-calc(440);
$medium-breakpoint: em-calc(640);
$large-breakpoint: em-calc(840);
$xlarge-breakpoint: em-calc(1280);

$small-range: (0, $small-breakpoint) !default;
$medium-range: ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range: ($medium-breakpoint + em-calc(1), $large-breakpoint) !default;
$xlarge-range: ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$text-direction: ltr !default;
$default-float: left !default;
$opposite-direction: right !default;

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);

$cursor-auto-value: auto !default;
$cursor-pointer-value: pointer !default;

// catalog
$catalog-header-height: 94px;

// grid
$gutter-size-sm: 0.5rem;
$gutter-size-lg: 0.75rem;

// forms
$form-input-padding: 0.625rem;

// modals
$modal-padding-sm: 0.625rem;
$modal-padding-lg: 1.5rem;

// tooltips
$tooltip-bg: rgba(36, 35, 52, 0.9);
$tooltip-arrow-width: 10px;

$header-top-height: 2.5rem;
$header-bottom-height: 1.5rem;
$header-height: $header-top-height + $header-bottom-height;
$hello-bar-height: 2rem;
$mobile-header-height: 2.75rem;

$header-component-height: $header-height + $hello-bar-height;
$header-component-height-small: $mobile-header-height + $hello-bar-height;

$office-header-height: 70px;
$office-header-height-mobile: 70px;

@mixin smooth-scroll($dim: 3px) {
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
  }

  &::-webkit-scrollbar {
    width: $dim;
    height: $dim;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    border-radius: 100px;
  }
}
