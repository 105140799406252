$sm: 440px;
$md: 640px;
$lg: 840px;
$xl: 1280px;

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);

  @if $type == string {
    @if $min == sm {
      @media (max-width: #{$sm}) { @content; }
    }
    @else if $min == sm-up {
      @media (min-width: 0px) { @content; }
    }
    @else if $min == md-down {
      @media (max-width: #{$md}) { @content; }
    }
    @else if $min == md {
      @if $max == lg {
        @media (min-width: #{$sm + 1}) and (max-width: #{$lg}) { @content; }
      }
      @else if $max == xl {
        @media (min-width: #{$sm + 1}) and (max-width: #{$xl}) { @content; }
      }
      @else {
        @media (min-width: #{$sm + 1}) and (max-width: #{$md}) { @content; }
      }
    }
    @else if $min == md-up {
      @media (min-width: #{$sm + 1 }) { @content; }
    }
    @else if $min == lg-down {
      @media (max-width: #{$lg}) { @content; }
    }
    @else if $min == lg {
      @if $max == xl {
        @media (min-width: #{$md + 1}) and (max-width: #{$xl}) { @content; }
      }
      @else {
        @media (min-width: #{$md + 1}) and (max-width: #{$lg}) { @content; }
      }
    }
    @else if $min == lg-up {
      @media (min-width: #{$md + 1}) { @content; }
    }
    @else if $min == xl-down {
      @media (max-width: #{$xl}) { @content; }
    }
    @else if $min == xl {
      @media (min-width: #{$lg+1}) and (max-width: #{$xl}) { @content; }
    }
    @else if $min == xl-up {
      @media (min-width: #{$lg + 1}) { @content; }
    }
    @else if $min == xxl-up {
      @media (min-width: #{$xl + 1}) { @content; }
    }

    @else {
      @warn "Breakpoint mixin supports: sm, sm-up, md-down, md, md-up, lg-down, lg, lg-up, xl-down, xl, xl-up, and xxl-up";
    }
  }

  @else if $type == number {
    $query: "all" !default;
    @if $min != 0 and $max != 0 {
      $query: "(min-width: #{$min}) and (max-width: #{$max})";
    }
    @else if $min != 0 and $max == 0 {
      $query: "(min-width: #{$min})";
    }
    @else if $min == 0 and $max != 0 {
      $query: "(max-width: #{$max})";
    }
    @media #{$query} {
      @content;
    }
  }
}
