@import 'foundation_settings';

// Must be 100% for off canvas to work
html, body {
  height: 100%;
  line-height: $base-line-height;
  font-size: $base-font-size;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

// Set box-sizing globally to handle padding and border widths
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

* {
  @include smooth-scroll;
}

// Default body styles
body {
  background: $body-bg;
  color: $body-font-color;
  cursor: $cursor-auto-value;
  font-family: $body-font-family;
  font-style: $body-font-style;
  font-weight: $body-font-weight;
  line-height: $base-line-height; // Set to $base-line-height to take on browser default of 150%
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  color: $black;
  font-family: $primary-font;
  font-weight: normal;
  font-style: normal;
}

a:hover { cursor: $cursor-pointer-value; }

a,
a:hover,
a:focus {
  color: $primary-color;
  text-decoration: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

// Grid Defaults to get images and embeds to work properly
img { max-width: 100%; height: auto; }

img { -ms-interpolation-mode: bicubic; }

// Get rid of gap under images by making them display: inline-block; by default
img {
  display: inline-block;
  vertical-align: middle;
}

#map_canvas,
.map_canvas,
.mqa-display {
  img,
  embed,
  object { max-width: none !important;
  }
}

// Miscellaneous useful HTML classes
.left { float: left !important; }
.right { float: right !important; }

// Hide visually and from screen readers
.hide {
  display: none;
}

// Hide visually and from screen readers, but maintain layout
.invisible { visibility: hidden; }

// Font smoothing
// Antialiased font smoothing works best for light text on a dark background.
// Apply to single elements instead of globally to body.
// Note this only applies to webkit-based desktop browsers and Firefox 25 (and later) on the Mac.
.antialiased { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

//
// Global resets for forms
//

// Make sure textarea takes on height automatically
textarea { height: auto; min-height: 50px; }

// Make select elements 100% width by default
select { width: 100%; }
